











import { Form } from '../components/admin';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'Debug',
  components: {
    Form,
  },
})
export default class Debug extends Vue {
  model = {
    name: 'cool',
    dashboardDesign: {
      logo:
        'https://www.deluxe.com/sites/www.deluxe.com/files/logo_design/logo/colorpro.png',
      backgroundColor: '#409EFF',
    },
    // 'dashboardDesign.logo': 'wrong'
    logo:
      'https://www.deluxe.com/sites/www.deluxe.com/files/logo_design/logo/colorpro.png',
    fieldOne: '1',
    fieldTwo: '2',
  };
  schema = [
    {
      key: 'name',
      rules: [
        {
          required: true,
        },
      ],
    },
    {
      key: 'dashboardDesign.logo',
      type: 'file',
      rules: [
        {
          required: true,
        },
      ],
    },
    {
      key: 'dashboardDesign.backgroundColor',
      type: 'color',
      rules: [
        {
          required: true,
        },
      ],
    },
    {
      type: 'column',
      fields: [
        {
          key: 'fieldOne',
        },
        {
          key: 'fieldTwo',
        },
      ],
    },
  ];
  onSubmit() {}
}
